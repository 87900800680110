// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-list1-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.content-list1-max-width {
  width: 100%;
  display: flex;
  max-width: 1400px;
  align-items: center;
  flex-direction: column;
}
.content-list1-content {
  align-self: stretch;
  align-items: flex-start;
}
.content-list1-ul {
  align-items: flex-start;
}
.content-list1-heading1 {
  align-self: flex-start;
  font-style: normal;
  font-weight: 400;
}
.content-list1-root-class-name {
  fill: var(--dl-color-gray-black);
  color: var(--dl-color-gray-black);
}
`, "",{"version":3,"sources":["webpack://./src/components/content-list1.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;AACA;EACE,sBAAsB;EACtB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,gCAAgC;EAChC,iCAAiC;AACnC","sourcesContent":[".content-list1-container {\n  width: 100%;\n  height: auto;\n  display: flex;\n  position: relative;\n  align-items: center;\n  flex-direction: column;\n}\n.content-list1-max-width {\n  width: 100%;\n  display: flex;\n  max-width: 1400px;\n  align-items: center;\n  flex-direction: column;\n}\n.content-list1-content {\n  align-self: stretch;\n  align-items: flex-start;\n}\n.content-list1-ul {\n  align-items: flex-start;\n}\n.content-list1-heading1 {\n  align-self: flex-start;\n  font-style: normal;\n  font-weight: 400;\n}\n.content-list1-root-class-name {\n  fill: var(--dl-color-gray-black);\n  color: var(--dl-color-gray-black);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
