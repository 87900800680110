// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.solid-button-container {
  display: flex;
  position: relative;
}
.solid-button-button {
  color: var(--dl-color-gray-white);
  border: none;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 50px;
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-primary-100);
}
`, "",{"version":3,"sources":["webpack://./src/components/solid-button.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,iCAAiC;EACjC,YAAY;EACZ,2CAA2C;EAC3C,8CAA8C;EAC9C,mBAAmB;EACnB,+CAA+C;EAC/C,8CAA8C;EAC9C,6CAA6C;AAC/C","sourcesContent":[".solid-button-container {\n  display: flex;\n  position: relative;\n}\n.solid-button-button {\n  color: var(--dl-color-gray-white);\n  border: none;\n  padding-top: var(--dl-space-space-halfunit);\n  padding-left: var(--dl-space-space-doubleunit);\n  border-radius: 50px;\n  padding-right: var(--dl-space-space-doubleunit);\n  padding-bottom: var(--dl-space-space-halfunit);\n  background-color: var(--dl-color-primary-100);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
