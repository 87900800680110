// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.place-card-container {
  width: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.place-card-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-top-left-radius: var(--dl-radius-radius-radius8);
  border-top-right-radius: var(--dl-radius-radius-radius8);
}
.place-card-container1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: column;
}
.place-card-text {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: var(--dl-space-space-halfunit);
}
.place-card-text1 {
  font-size: 12px;
  max-width: 250px;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.place-card-root-class-name {
  flex: 1 1;
  left: 0px;
  align-self: stretch;
}
@media(max-width: 767px) {
  .place-card-container {
    width: 200px;
  }
}
@media(max-width: 479px) {
  .place-card-container {
    width: 300px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/place-card.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,uDAAuD;EACvD,wDAAwD;AAC1D;AACA;EACE,WAAW;EACX,aAAa;EACb,mCAAmC;EACnC,mBAAmB;EACnB,sBAAsB;AACxB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,8CAA8C;AAChD;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,+CAA+C;AACjD;AACA;EACE,SAAO;EACP,SAAS;EACT,mBAAmB;AACrB;AACA;EACE;IACE,YAAY;EACd;AACF;AACA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".place-card-container {\n  width: 300px;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n}\n.place-card-image {\n  width: 100%;\n  height: 250px;\n  object-fit: cover;\n  border-top-left-radius: var(--dl-radius-radius-radius8);\n  border-top-right-radius: var(--dl-radius-radius-radius8);\n}\n.place-card-container1 {\n  width: 100%;\n  display: flex;\n  padding: var(--dl-space-space-unit);\n  align-items: center;\n  flex-direction: column;\n}\n.place-card-text {\n  font-size: 20px;\n  font-style: normal;\n  font-weight: 700;\n  padding-bottom: var(--dl-space-space-halfunit);\n}\n.place-card-text1 {\n  font-size: 12px;\n  max-width: 250px;\n  margin-bottom: var(--dl-space-space-doubleunit);\n}\n.place-card-root-class-name {\n  flex: 1;\n  left: 0px;\n  align-self: stretch;\n}\n@media(max-width: 767px) {\n  .place-card-container {\n    width: 200px;\n  }\n}\n@media(max-width: 479px) {\n  .place-card-container {\n    width: 300px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
