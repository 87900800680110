// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outline-button-container {
  display: flex;
  position: relative;
}
.outline-button-button {
  color: var(--dl-color-gray-black);
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-primary-100);
  border-width: 1px;
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 50px;
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-white);
}
`, "",{"version":3,"sources":["webpack://./src/components/outline-button.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,iCAAiC;EACjC,uCAAuC;EACvC,yCAAyC;EACzC,iBAAiB;EACjB,8CAA8C;EAC9C,mBAAmB;EACnB,+CAA+C;EAC/C,0CAA0C;EAC1C,4CAA4C;AAC9C","sourcesContent":[".outline-button-container {\n  display: flex;\n  position: relative;\n}\n.outline-button-button {\n  color: var(--dl-color-gray-black);\n  padding-top: var(--dl-space-space-unit);\n  border-color: var(--dl-color-primary-100);\n  border-width: 1px;\n  padding-left: var(--dl-space-space-doubleunit);\n  border-radius: 50px;\n  padding-right: var(--dl-space-space-doubleunit);\n  padding-bottom: var(--dl-space-space-unit);\n  background-color: var(--dl-color-gray-white);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
