import React from 'react'

import PropTypes from 'prop-types'

import './content-list1.css'

const ContentList1 = (props) => {
  return (
    <div
      className={`content-list1-container thq-section-padding ${props.rootClassName} `}
    >
      <div className="content-list1-max-width thq-section-max-width">
        <div className="content-list1-content thq-flex-column">
          <ul className="content-list1-ul thq-flex-column">
            <li className="thq-flex-column list-item">
              <h2 className="content-list1-heading1 thq-heading-2">
                {props.heading1}
              </h2>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

ContentList1.defaultProps = {
  rootClassName: '',
  heading1: 'Creating human capital by fostering a culture of learning',
}

ContentList1.propTypes = {
  rootClassName: PropTypes.string,
  heading1: PropTypes.string,
}

export default ContentList1
